import GameSearch from '@/components/features/search';
import React from 'react';
import { Box, Grid, Stack } from '@mui/material';
import WrappedSelector from '@/components/ui/WrappedSelector';
import { useTranslation } from 'next-i18next';
import useTheme from '@/theme/useTheme';
import { SortByEnum } from '@/components/pages/casino/helper';

const GameSearchWithFilter = ({
  setFilter,
  filter,
  productList,
  enabledFilters,
}: {
  setFilter: (filter: any) => void;
  filter: any;
  productList: string[];
  enabledFilters?: {
    provider?: boolean;
    sortBy?: boolean;
  };
}) => {
  const [t] = useTranslation();
  const { theme } = useTheme();

  const productFilterOptions = [t('All')].concat(productList);
  const sortByFilterOptions = [
    t('Default'),
    t('Alphabetical A-Z'),
    t('Alphabetical Z-A'),
    t('New Releases'),
  ];

  return (
    <Grid
      sx={{
        display: 'grid',
        gridTemplateColumns: `auto ${'min-content '.repeat(
          Object.values(enabledFilters).filter(Boolean).length,
        )}`,
        gap: 1,
        [theme.breakpoints.down('sm')]: {
          gridTemplateRows: 'auto auto',
          gridTemplateColumns: '1fr 1fr',
        },
      }}
    >
      <Box
        sx={{
          [theme.breakpoints.down('sm')]: {
            gridColumnStart: 1,
            gridColumnEnd: 3,
          },
        }}
      >
        <GameSearch
          mode={'searchOnly'}
          onChange={(value) => {
            setFilter((prev) => ({
              ...prev,
              searchValue: value.trim(),
            }));
          }}
        />
      </Box>
      {enabledFilters.provider && (
        <WrappedSelector<string>
          value={filter.provider || productFilterOptions[0]}
          list={productFilterOptions}
          onChange={(e) => {
            setFilter((prev) => ({
              ...prev,
              provider:
                e.target.value === productFilterOptions[0]
                  ? undefined
                  : e.target.value,
            }));
          }}
          renderValue={(item: string) => (
            <Stack direction={'row'} alignItems={'center'} gap={1}>
              {t('Provider')}:{' '}
              <Box component={'span'} color={'primary.main'}>
                {item}
              </Box>
            </Stack>
          )}
        ></WrappedSelector>
      )}
      {enabledFilters.sortBy && (
        <WrappedSelector<number>
          value={filter.sortBy || 0}
          list={
            Object.values(SortByEnum).filter(
              (i) => !isNaN(Number(i)),
            ) as number[]
          }
          onChange={(e) => {
            setFilter((prev) => ({
              ...prev,
              sortBy: Number(e.target.value) === 0 ? undefined : e.target.value,
            }));
          }}
          renderValue={(item: number) => (
            <Stack direction={'row'} alignItems={'center'} gap={1}>
              {t('Sort By')}:{' '}
              <Box component={'span'} color={'primary.main'}>
                {sortByFilterOptions[item]}
              </Box>
            </Stack>
          )}
          render={(item) => {
            return sortByFilterOptions[item];
          }}
        ></WrappedSelector>
      )}
    </Grid>
  );
};

export default GameSearchWithFilter;
