import useTheme from '@/theme/useTheme';
import { Box, Link } from '@mui/material';

const TransLink = (props) => {
  const { theme } = useTheme();

  return (
    <Link
      style={{
        color: theme.palette.primary.main,
      }}
      href={props.link}
      // target={'_blank'}
      {...props}
    />
  );
};

const HighlightText = (props) => {
  const { theme } = useTheme();
  return (
    <span
      style={{
        color: theme.palette.primary.main,
      }}
      {...props}
    />
  );
};
const casino = {
  title: 'Play Online Casino Games for Real Money',
  abstractText:
    'MoonClub Casino is here to blast your gaming off into the stratosphere. You can earn money back on every bet, making MoonClub the most rewarding online casino. You can get started without any fuss, thanks to our no KYC casino process. There are hundreds of the best online casino games to play, with more being added every week. Strap in and enjoy a thrilling casino experience at MoonClub.com.',
  left: [
    {
      type: 'title',
      text: 'Play the Best Online Slot Games',
    },
    {
      type: 'text',
      text: 'MoonClub Casino is here to blast your gaming off into the stratosphere. You can earn money back on every bet, making MoonClub the most rewarding online casino. You can get started without any fuss, thanks to our no KYC casino process. There are hundreds of the best online casino games to play, with more being added every week. Strap in and enjoy a thrilling casino experience at MoonClub.com.',
    },
    {
      type: 'title',
      text: 'Play Online Blackjack ',
    },
    {
      type: 'text',
      text: 'MoonClub Casino is loaded with the most exciting online blackjack tables. You can go up against the dealer on the virtual felt and enjoy versatile betting limits. These games provide instant action without the fuss of waiting for others to bet. MoonClub features Blackjack Perfect Pairs, Blackjack Supreme, and Multihand Blackjack games. ',
    },
    {
      type: 'title',
      text: 'Hit the Online Roulette Tables',
    },
    {
      type: 'text',
      text: ' You will find plenty of online roulette tables at MoonClub Casino. We stock European, American, and French Roulette options. These games use a random number generator to determine the results, the same technology used in slot games. The RNG engine ensures all payouts are random and fair. Additionally, you can enjoy low table stakes or high table stakes, so all budget sizes are welcome. ',
    },
    {
      type: 'title',
      text: 'Immerse Yourself in Live Dealer Games',
    },
    {
      type: 'text',
      text: 'The live dealer games give you the most authentic gambling experience. These live dealer games are set in a real casino with real dealers and hosts at the helm. All the betting action is broadcast straight to your screen in real-time, so you can enjoy an immersive experience from the comfort of your home. The games are played in real-time, so there is no RNG engine to determine the results. The live dealer titles cover all types of casino games, with blackjack, roulette, baccarat, and others. These live games are suitable for all budget sizes, thanks to versatile table limits. These games feature multiple camera angles, and you can enjoy social interaction with other players and the dealers. ',
    },
    {
      type: 'title',
      text: 'Play Exclusive MoonClub Games',
    },
    {
      type: 'text',
      text: 'Here at MoonClub, we built games that can only be played at MoonClub.com. These exclusive games blast your entertainment levels off into the stratosphere. You can’t find these games anywhere else, so be sure to check them out. Our exclusive titles include <rushLink>Moon Rush</rushLink>, <crashLink>Crash</crashLink>, and <moondrawLink>Moon Draw</moondrawLink>. We are always working on new releases, so there’s plenty to look forward to.',
      components: {
        moonLink: <TransLink link="/casino" />,
        rushLink: <TransLink link="/casino/rush" />,
        crashLink: <TransLink link="/casino/crash" />,
        moondrawLink: <TransLink link="/casino/draw" />,
      },
    },
  ],
  right: [
    {
      type: 'title',
      text: 'Play Online Casino Games for Free',
    },
    {
      type: 'text',
      text: 'MoonClub allows all RNG casino games to be played for free. All slots and tables can be enjoyed in free-play mode. You will see the free-play toggle within each game window. Checking out the casino games for free carries no risk at all. It’s also a great way to view the game’s theme and experience the gameplay. You can also learn the rules or practice different strategies, so you’re better equipped when you play casino games for real money. ',
    },
    {
      type: 'title',
      text: 'Play Mobile Casino Games',
    },
    {
      type: 'text',
      text: 'MoonClub is a mobile-friendly casino, so you can enjoy mobile casino games from wherever your day takes you. All the casino games are optimized to provide a fully responsive performance on your iOS or Android mobile device. All casino games are available on smartphone and tablet devices, including slots, tables, specialty games, and live casino games. The mobile casino experience offers an immersive view with awesome detail, so you don’t have to compromise on quality. You can play mobile games by heading over to MoonClub through your mobile browser. ',
    },
    {
      type: 'text',
      text: 'Furthermore, MoonClub Casino is available to play directly through the Telegram app. The <tgLink>Telegram casino</tgLink> gives you instant access to the best casino games and all the MoonClub benefits. You can also enjoy mobile convenience, ultimate privacy, and complete anonymity. ',
      components: {
        tgLink: <TransLink link="https://t.me/MoonClubcom_official" />,
      },
    },
    {
      type: 'title',
      text: 'Play the Best Casino Games with Cryptocurrency',
    },
    {
      type: 'text',
      text: 'MoonClub is the most rewarding crypto casino online. We make it easy to deposit using cryptocurrency. All bets are made in USDT, so you have control and transparency on how much you are betting. Playing with cryptocurrency offers lots of great benefits, such as improved payment security and faster withdrawal times. ',
    },
    {
      type: 'title',
      text: 'Earn Rewards on all Casino Games (about Rakeback)',
    },
    {
      type: 'text',
      text: 'Here at MoonClub, we built games that can only be played at MoonClub.com. These exclusive games blast your entertainment levels off into the stratosphere. You can’t find these games anywhere else, so be sure to check them out. Our exclusive titles include Moon Rush, Crash, and Moon Draw. We are always working on new releases, so there’s plenty to look forward to.',
    },
    {
      type: 'title',
      text: 'Play the Highest Rewarding Online Casino ',
    },
    {
      type: 'text',
      text: 'MoonClub.com is designed to provide you with the best online gaming experience. There is no KYC or verification, so you can sign up without any hassle and reap all the rewards. New players can enjoy a generous welcome bonus, and there’s lots more to get excited about. Besides the money back on every bet, you can also earn rewards by inviting friends to join. ',
    },
  ],
};

const slots = {
  title: 'Play Online Slots for Real Money',
  abstractText:
    'The slot games make up the lion’s share of the game catalog at MoonClub. These thrilling slots for real money come in all shapes and sizes, with all the themes and genres you can imagine. MoonClub Casino makes slot play even more rewarding by offering a 6% Rakeback. This means you get money back on every bet, whether you win or lose. If regular online casinos offer a 96.4% return, MoonClub offers a 97% return, so you can enjoy more real money funds to play with. Our slots suit all budget sizes, and you even have the option to play for free. ',
  left: [
    {
      type: 'title',
      text: 'Play the Most Popular Slot Games ',
    },
    {
      type: 'text',
      text: 'MoonClub Casino is home to hundreds of online slot games, with new games added every week. We host slot machines from the most in-demand gaming providers, including Pragmatic Play and Betsoft. If you’re new to slots, you can check out our guide on how to play slots online. Here are the most popular online slots currently available at MoonClub.',
    },
    {
      type: 'text',
      text: 'Big Bass Bonanza: The Big Bass Bonanza slot from Pragmatic Play in the original in the Big Bass series has stood the test of time, to say the least. This game is set on five reels with ten fixed paylines, boasting a generous 96.71% RTP. There’s plenty of bonus action, with a free spins feature, wilds, and multipliers. ',
    },
    {
      type: 'text',
      text: 'Thai Blossoms: The Asian-themed Thai Blossoms slot from Betsoft brings the beauty of Thailand to the reels. The game features some exciting extras, including stacked wilds and sticky wilds. The online slot game is based on a five-reel grid with four rows and 100 paylines. The slot also has a 96.21% RTP. ',
    },
    {
      type: 'text',
      text: 'Wish Upon a Jackpot Megaways: The fairytale-themed Wish Upon a Jackpot Megaways from Blueprint Gaming delivers high-octane gameplay. The Megaways engine means the game delivers up to 117,649 ways to win. The game is stacked with rewarding treats, including random wilds, stacked wilds with multipliers, and a free spins bonus round. There are seven bonus features to trigger in this game. Set on six reels with five rows, the game has a staggering max win amount of 50,000x your stake. ',
    },
    {
      type: 'title',
      text: 'Play Crypto Slot Games ',
    },
    {
      type: 'text',
      text: 'All the slot games at MoonClub.com can be played using cryptocurrency, using Tether. We are adding more crypto options, such as Bitcoin and Ethereum. Playing slots with cryptocurrency offers many enticing benefits, such as lightning-fast withdrawals and unrivaled payment security. ',
    },
    {
      type: 'title',
      text: 'Slot Bonus Features',
    },
    {
      type: 'text',
      text: 'If you’re the type of player who likes edge-of-your-seat bonus play, then you will love slots with bonus rounds. These bonus features are designed to mix up the gameplay while delivering big wins. These innovative and exciting extras are usually the main attraction, where the game’s biggest wins are achieved. There are many different types of bonus rounds, including free spins, pick-me features, and more. The latest slot games have innovative bonus rounds that are getting more engaging. You will also find Bonus Buy slot games that allow you to buy straight into the bonus round so you can bypass the need to land the required symbols. ',
    },
  ],
  right: [
    {
      type: 'title',
      text: 'How Do Online Slots Work?',
    },
    {
      type: 'text',
      text: 'Online slot games use a Random Number Generator (RNG) to determine the results. The RNG engine ensures the reels spin with completely randomized results. This means that each spin round is independent of the previous and any spin. The RNG engine promises fair results every time. Additionally, MoonClub works with iTechLabs to ensure every game is fair. iTechLabs is an independent auditing service that carries out additional tests on every RNG game in the casino. ',
    },
    {
      type: 'title',
      text: 'About Slot Volatility and RTP',
    },
    {
      type: 'text',
      text: 'Each online slot game has a volatility and RTP rating. This allows you to work out the expected payout and the frequency of wins. The volatility ranges from low, medium, and high. The average RTP for a slot game is around 96%, which gives the casino a house edge of 4%. However, MoonClub’s Rakeback rewards will reduce the house edge to 3.4%. ',
    },
    {
      type: 'text',
      text: 'Volatility: The volatility levels determine the payout activity. If a slot game has low volatility, it usually means the game pays out frequently, but the win amounts are low. If a game has high volatility, it doesn’t pay out as frequently, but when it does, the wins are much larger. ',
    },
    {
      type: 'text',
      text: 'RTP: The RTP percentage is calculated over thousands of spins. This number allows you to work out a theoretical return rate. For example, if a game has a 96% RTP, in theory, the game should pay out $96 for every $100 wagered. Of course, this is a calculation made over thousands and thousands of spins.           ',
    },
    {
      type: 'title',
      text: 'The Best 3D Slot Games at MoonClub',
    },
    {
      type: 'text',
      text: 'MoonClub is home to all the best online slots, from the classic three-reel slot machines to the latest releases with 3D graphics. You can indulge in slot play and immerse yourself in the action with stunning designs, thrilling bonus extras, and exhilarating gameplay. Our collection of new 3D slots brings characters to life and immerses you in the moment with every spin. Some of our most exciting 3D slot games include The Dog House Megaways, Blazing Bull 2, and Sweet Bonanza.  ',
    },
    {
      type: 'title',
      text: 'Play the Best Mobile Slot Games',
    },
    {
      type: 'text',
      text: 'You can play the best in-demand slot games through your mobile device at MoonClub Casino. MoonClub is available on all iOS and Android mobile devices. You just need to head over to MoonClub.com through your mobile browser. You can even save MoonClub to your mobile screen by downloading the casino app to your mobile here. All casino games are optimized to perform on mobile.  ',
    },
    {
      type: 'title',
      text: 'Play Casino Slot Games on Telegram',
    },
    {
      type: 'text',
      text: 'MoonClub is also available through Telegram. This allows you to enjoy the fully loaded casino experience directly through the Telegram app. Playing through Telegram gives you ultimate security and privacy. MoonClub is a no-KYC casino, so you can sign up without providing sensitive personal details. ',
    },
  ],
};

const blackjack = {
  title: 'Play Blackjack Online for Real Money',
  abstractH2: 'How to Play Online Blackjack',
  abstractText: `The game of blackjack is one of the most popular titles in the casino because it's easy to play and offers great odds. Black is a card game that puts the player against the dealer, with the better hand winning the round. Each player is dealt two cards, with the option to take further cards. In order to beat the dealer, you have to have a card value closer to 21. If you go over 21, you lose the round. In most versions of the game, the dealer must stick to 17, which means they can't take any more cards if their score is 17 or more. If you are dealt a perfect score of 21 (ten and ace), this is called a natural blackjack and is the best hand you can land. This hand also pays the biggest win, with a payout of 3:2 instead of the standard 1:1 payout for other wins. `,
  left: [
    {
      type: 'title',
      text: 'Basic Online Blackjack Rules',
    },
    {
      type: 'text',
      text: `Playing online blackjack at MoonClub is an easy and enjoyable experience. The game puts the player against the dealer, and whoever has the better hand wins. If you go over 21, you bust and lose the round; the same rules apply to the dealer. Here is a breakdown of the blackjack rules:`,
    },
    {
      type: 'text',
      text: '<tlink>Hit:</tlink> This is when you add another card to your hand in a bid to increase the value of the cards. You can hit as many times as you want until your value is over 21, and bust. ',
      components: {
        tlink: <HighlightText />,
      },
    },
    {
      type: 'text',
      text: `<tlink>Stand:</tlink> This is when you have a blackjack hand that you are happy with, and you don't want any more cards. `,
      components: {
        tlink: <HighlightText />,
      },
    },
    {
      type: 'text',
      text: `<tlink>Double: </tlink> You can increase your bet size by double after the first cards are dealt and take one more card. `,
      components: {
        tlink: <HighlightText />,
      },
    },
    {
      type: 'text',
      text: `<tlink>Surrender:</tlink> You can also find online blackjack games that offer a surrender. This allows you to give up your blackjack hand in return for half your stake back. `,
      components: {
        tlink: <HighlightText />,
      },
    },
    {
      type: 'text',
      text: `<tlink>Insurance:</tlink>Many versions of blackjack feature the insurance bet. This is available when the dealer is showing a ten or ace. This side bet allows you to protect yourself in case the dealer hits blackjack, so you don't lose this stake. `,
      components: {
        tlink: <HighlightText />,
      },
    },
    {
      type: 'title',
      text: 'How to Win at Online Blackjack  ',
    },
    {
      type: 'text',
      text: `Playing online blackjack offers unrivaled betting entertainment, and the odds of hitting wins are very good. However, you can apply the basic blackjack strategy and minimize the house edge from around 2% to under 1%. The basic strategy in blackjack tells you exactly what you should do based on your cards and the cards that the dealer is holding. This allows you to either bet or fold with confidence while enjoying the best chances of winning. You will need to take some time to learn these bets or have the blackjack strategy chart available while you play. The strategy may also vary, depending on how many decks are in play. The best blackjack odds are usually within games with fewer decks in circulation. At MoonClub, you can check out the blackjack games for free with demo mode. This is a great way to practice the basic blackjack strategy before you bet with real money. `,
    },
  ],
  right: [
    {
      type: 'title',
      text: `Play the Best Online Blackjack Games`,
    },
    {
      type: 'text',
      text: `The benefit of playing blackjack online at MoonClub is that you have more choices of blackjack games. You can also play for free and bet with amounts that suit your budget, all from the comfort of your home. You can find the most popular online blackjack games at MoonClub, with many variations to choose from. Besides virtual blackjack that uses RNG (random number generator) engines, you can check out live blackjack games that are played in real-time at a land-based casino. Here are some of the best blackjack games available at MoonClub.`,
    },
    {
      type: 'text',
      text: `<tlink>Single Deck Blackjack:</tlink> TSingle Deck Blackjack is the most sought-after version of virtual blackjack. This is because the house edge is slightly lower than in blackjack games, which use more decks of cards. This game also makes it easier to follow the strategy and allows you to make calculated decisions when it comes to betting. Our version of Single Deck Blackjack from Betsoft offers instant betting action in a clean-cut and attractive virtual gaming environment. `,
      components: {
        tlink: <HighlightText />,
      },
    },
    {
      type: 'text',
      text: `<tlink>Blackjack Perfect Pairs: </tlink>Another popular online blackjack table is the Blackjack Perfect Pairs game. This version has the same rules as the standard blackjack tables but with an additional side bet. This bonus side bet allows you to place a bet on whether perfect pairs will be dealt. This side bet pays between 6:1 and 25:1 for landing a pair when the cards are dealt. `,
      components: {
        tlink: <HighlightText />,
      },
    },
    {
      type: 'text',
      text: `<tlink>Blackjack Supreme Multihand:</tlink> This exciting variation of blackjack ramps up the excitement by allowing you to play up to three blackjack hands at a time. That's not all; it also features the perfect pair's side bet, so you can really immerse yourself in the thrills and spills of the classic table game. The game is designed with VIP in mind, a sophisticated environment, and large betting limits. `,
      components: {
        tlink: <HighlightText />,
      },
    },
    {
      type: 'title',
      text: `Why is Blackjack so Popular?`,
    },
    {
      type: 'text',
      text: `Blackjack stands as one of the most popular games in the casino because it's easy to play, the rules are simple, and the odds of winning are great. The blackjack tables offer some of the lowest house edges in the casino. The game has long been a favored choice amongst casino enthusiasts, and with many new versions to enjoy, the game maintains its popularity. `,
    },
    {
      type: 'title',
      text: `Blackjack at MoonClub Casino`,
    },
    {
      type: 'text',
      text: `At MoonClub Casino, we can't get enough of Blackjack. We are always adding new blackjack tables to the roster, so you can enjoy versions offering a new twist, a different theme, or extra bets. At MoonClub, you can play blackjack for real money or enjoy our free choice of RNG blackjack tables. `,
    },
  ],
};
const roulette = {
  title: 'Play Roulette Online for Real Money',
  left: [
    {
      type: 'title',
      text: 'Online Roulette Games at MoonClub',
    },
    {
      type: 'text',
      text: `Playing roulette offers a thrill like no other casino game. At MoonClub, you can enjoy a variety of roulette tables, with American Roulette, European Roulette, French Roulette, and other versions available. The online roulette tables come in many unique forms, with many versions offering unique themes or bets. These games are suitable for all budget sizes, with table limits ranging from $0.10 to $1000. You can pick from virtual roulette or immerse yourself in the live casino roulette tables. The live games replicate the land-based casino experience, with bets placed through a high-quality stream in real-time.`,
    },
    {
      type: 'title',
      text: 'How to Play Online Roulette',
    },
    {
      type: 'text',
      text: 'Playing online roulette offers endless fun and entertainment. The game is set on a table with up to 38 numbered sections, which are the same numbers on the roulette wheel. You can place bets on the numbers, including the corners and sides of the numbers, allowing one bet to cover up to four different numbers. You can also place even bets on red or black, or odd or even, not to mention the other betting options on the table. Once the bets are placed, the roulette wheel is spun, and the roulette ball will eventually stop on a winning number. Any losing bets are removed from the table, and the winning bets will receive the winnings. The game of roulette is based entirely on luck, with no skill involved.',
    },
    {
      type: 'title',
      text: 'Roulette Bets and Odds',
    },
    {
      type: 'text',
      text: 'The roulette table has a wide choice of bets available, all of which come with different odds. The bets are split into inside bets and outside bets. ',
    },
    {
      type: 'title',
      text: 'Inside Bets',
    },
    {
      type: 'text',
      text: `The inside bets are the bets placed within the table, such as betting numbers. The inside bets have higher odds and bigger payouts. Here is a breakdown of the different types of inside bets on the roulette table.
      Straight up: A straight bet is the most common bet on the roulette table. This is a bet on a number you think the roulette ball will land on. This bet offers the biggest returns with a 35:1 payout.
      Split Bet: The split bet is placed across two separate numbers. This allows you to cover multiple numbers, but the payouts are reduced. If the ball lands on either number, you will get a payout of 17:1.
       `,
    },
    {
      type: 'text',
      text: `<tbold>Street Bet</tbold>: A street bet covers three numbers in a row, such as 13, 14, and 15. This type of bet pays out 11:1.`,
      components: {
        tbold: <span style={{ fontWeight: 600 }} />,
      },
    },
    {
      type: 'text',
      text: `<tbold>Corner Bet</tbold>: This bet allows you to bet across four numbers on the table. For example, you can wager on 16, 17, 18, and 19. If the ball lands on these numbers, you win a payout of 8:1.`,
      components: {
        tbold: <span style={{ fontWeight: 600 }} />,
      },
    },
    {
      type: 'text',
      text: `<tbold>Five Bet</tbold>: The five bet is only available in American Roulette and allows you to place the bet on 0, 00, 1, 2, and 3. The chip is placed on the corner of 0 and 1, covering five numbers. This wager pays out 6:1.`,
      components: {
        tbold: <span style={{ fontWeight: 600 }} />,
      },
    },
    {
      type: 'text',
      text: `<tbold>Line Bet</tbold>: The line bet covers two rows and three numbers. You need to place the chip at the end of and between the rows you want to wager on, paying out 5:1.`,
      components: {
        tbold: <span style={{ fontWeight: 600 }} />,
      },
    },
  ],
  right: [
    {
      type: 'title',
      text: 'Outside Bets and Odds ',
    },
    {
      type: 'text',
      text: `The outside bets are outside of the numbered grid. These bets are considered lower risk compared to inside wagers. These bets allow you to wager on a broader outcome, such as 12 numbers, the color, odds, and even. Check out the types of outside bets below.`,
    },
    {
      type: 'text',
      text: `<tbold>Column Bet</tbold>: The column bet covers nearly a third of all the numbers. This bet will pay out 2:1 if the roulette balls land on one of the numbers within the column.`,
      components: {
        tbold: <span style={{ fontWeight: 600 }} />,
      },
    },
    {
      type: 'text',
      text: `<tbold>Dozen Bet</tbold>: The dozen also covers nearly a third of the roulette numbers. You can cover the first 12 (1-12), the second 12 (13-24), or the third 12 (25-36). The return for a dozen bet is 2:1.`,
      components: {
        tbold: <span style={{ fontWeight: 600 }} />,
      },
    },
    {
      type: 'text',
      text: `<tbold>Color Bets</tbold>: This is a bet on whether the roulette ball will land on red or black. The chances of winning are almost 50/50. The payout for a color bet is 1:1.`,
      components: {
        tbold: <span style={{ fontWeight: 600 }} />,
      },
    },
    {
      type: 'text',
      text: `<tbold>Even or Odd Bets</tbold>: This type of bet also has near-even split odds with a 50/50 chance. This is a bet on if the number will be odd or even. The payout return is 1:1.`,
      components: {
        tbold: <span style={{ fontWeight: 600 }} />,
      },
    },
    {
      type: 'text',
      text: `<tbold>Low or High Odd Bets</tbold>: This bet also has near-even odds at 50/50. You can bet on the number being low (numbers 1-18) or high (numbers 19-36), paying out 1:1.`,
      components: {
        tbold: <span style={{ fontWeight: 600 }} />,
      },
    },
    {
      type: 'title',
      text: 'Online Roulette Strategies',
    },
    {
      type: 'text',
      text: `Roulette is a game that has been around for centuries, and many roulette strategies have been devised over the years. These strategies have never been proven to beat the odds, but they can help keep your bets going for longer. Here are the most popular roulette strategies:`,
    },
    {
      type: 'text',
      text: `<tbold>Martingale Strategy</tbold>: This is the most popular roulette strategy. It’s very simple: every time you lose, you double your bet for the next spin. Eventually, you will hit a win and recoup any previous losses.`,
      components: {
        tbold: <span style={{ fontWeight: 600 }} />,
      },
    },
    {
      type: 'text',
      text: `<tbold>Paroli Strategy</tbold>: The Paroli roulette betting strategy is the opposite of the Martingale system. Rather than doubling your bet after a loss, you double after a win. After three wins, you reset back to the original bet stake.`,
      components: {
        tbold: <span style={{ fontWeight: 600 }} />,
      },
    },
    {
      type: 'text',
      text: `<tbold>D’Alembert Strategy</tbold>: The A D’Alembert roulette system is about increasing your bet by one after each loss and decreasing the bet by one after each win, which should reduce the impact of swings.`,
      components: {
        tbold: <span style={{ fontWeight: 600 }} />,
      },
    },
    {
      type: 'text',
      text: `<tbold>Fibonacci Strategy</tbold>: The Fibonacci roulette system uses a Fibonacci sequence: 1 – 1 – 2 – 3 – 5 – 8 – 13 – 21 – 34 – 55 – 89 etc. This shows each number is the sum of the two previous numbers. You only advance the betting sequence when you hit a win. If you bet one and win, you go to 2, then 3, then 5. If you hit a losing spin, you reset back to 1.`,
      components: {
        tbold: <span style={{ fontWeight: 600 }} />,
      },
    },
    {
      type: 'title',
      text: 'Roulette at MoonClub Casino',
    },
    {
      type: 'text',
      text: `You can find many top online roulette games at MoonClub Casino. Here, we have a selection of virtual and live casino roulette. You can find the regular rules across French, European, and American versions. You can also find games offering a unique twist to the conventional rules, with extra features and side bets. At MoonClub, you can play online roulette for real money or enjoy our free choice of RNG roulette tables.`,
    },
  ],
};

export default {
  casino,
  slots,
  blackjack,
  roulette,
};
