import { Input } from '@mui/material';

import { styled } from '@mui/material/styles';

const WrappedInput = styled(Input)(({ theme, error }) => ({
  padding: `7px 16px`,
  ['--border-color']: 'transparent',
  border: theme.palette.border.normal,
  borderRadius: theme.shape.borderRadius,
  // background: theme.palette.grey[1000],
  outline: 'none',
  boxShadow: 'none',
  fontSize: theme.typography.pxToRem(14),
  '&::before, &::after': {
    display: 'none',
  },
  '>input': {
    padding: 0,
    height: '1.5em',
  },
  '&:focus-within:not(.Mui-disabled), :hover:not(.Mui-disabled)': {
    ['--border-color']: theme.palette.primary.main,
  },
  ['.MuiFormControlLabel-asterisk']: {
    display: 'none',
  },
}));

export default WrappedInput;
