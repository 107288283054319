const mapFavoriteOrRecentToLocal = (
  gamesData,
  allGameList = [],
  slice = undefined,
) => {
  const unmapped =
    gamesData?.pages.length && gamesData.pages.every((page) => page?.games)
      ? gamesData.pages?.map((page) => page?.games)?.flat()
      : [];
  /**
   * also see apps/web/hooks/biz/casino/useFindMatchingGame.ts:9,30 when updating this section
   */
  const mapped = unmapped.map((item) => {
    const matchingGame = allGameList.find(
      (g) => g?.gameId === item?.gameId || g?.slug === item?.gameId,
    );
    return {
      ...item,
      ...matchingGame,
    };
  });
  return slice ? mapped.slice(0, slice) : mapped;
};

export default mapFavoriteOrRecentToLocal;
