import GameCardCarousel from '@/components/features/common/GameCardCarousel';
import { useTranslation } from 'next-i18next';
import { useInfiniteQuery } from '@tanstack/react-query';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import WrappedButton from '@/components/ui/WrappedButton';
import { useInViewport } from 'ahooks';
import { useEffect, useRef } from 'react';
import mapFavoriteOrRecentToLocal from '@/utils/biz/mapFavoriteOrRecentToLocal';
import { useAtom } from 'jotai';
import { gameListSelector } from '@/state/globalSettings';

const CasinoUserGameList = ({
  queryKey,
  queryFn,
  noDataText,
  title,
}: {
  queryKey: string[];
  queryFn: (pageParam: { nextId?: string; prevId?: string }) => Promise<{
    nextId?: string;
    prevId?: string;
    games: any[];
  }>;
  noDataText?: string;
  title: React.ReactNode;
}) => {
  const [t] = useTranslation();

  const loadMoreRef = useRef<HTMLDivElement>(null);

  const [inViewPort] = useInViewport(loadMoreRef);

  const [{ list }] = useAtom(gameListSelector);

  const { isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, data } =
    useInfiniteQuery({
      // @ts-ignore
      queryKey,
      queryFn: ({ pageParam }) => {
        return queryFn(pageParam);
      },
      getNextPageParam: (data, allPages) => {
        if (data.nextId) {
          return {
            nextId: data.nextId,
          };
        } else {
          return undefined;
        }
      },
    });

  useEffect(() => {
    if (inViewPort && hasNextPage) {
      fetchNextPage();
    }
  }, [inViewPort, hasNextPage]);

  const allGames = mapFavoriteOrRecentToLocal(data, list);

  return (
    <Box>
      {isLoading ? (
        <Stack alignItems={'center'}>
          <CircularProgress size={24} />
        </Stack>
      ) : allGames.length ? (
        <GameCardCarousel
          games={allGames}
          title={title}
          sxs={{
            wrapperSX: {
              maxHeight: undefined,
            },
          }}
        ></GameCardCarousel>
      ) : (
        <Typography textAlign={'center'}>{noDataText}</Typography>
      )}
      {hasNextPage && (
        <Box textAlign={'center'} mt={2} ref={loadMoreRef}>
          <WrappedButton
            isLoading={isFetchingNextPage}
            onClick={() => {
              fetchNextPage();
            }}
          >
            Load more
          </WrappedButton>
        </Box>
      )}
    </Box>
  );
};

export default CasinoUserGameList;
