import { SxProps, Theme } from '@mui/system';

export const HISTORY_SEARCH_ITEM_SX: SxProps<Theme> = {
  padding: (theme) => theme.spacing(1, 2),
  cursor: 'pointer',
  '&:hover': {
    background: (theme) => theme.palette.grey[700],
  },
};

export const SEARCH_WRAPPER_SX: SxProps<Theme> = {
  display: 'grid',
  gap: 1,
  gridTemplateColumns: 'repeat(auto-fill, minmax(140px, 1fr))',
};

export const SEARCH_RESULT_ITEM_SX: SxProps<Theme> = {
  // background: (theme) => theme.palette.grey[700],
  // aspectRatio: '140/186',
  // borderRadius: (theme) => theme.spacing(1),
  //   flex: '1 1 calc(100% / 4)',
};
