import getI18nProps from '@/utils/i18/getI18nProps';
import { styled } from '@mui/material/styles';
import CasinoPage from '@/components/pages/casino';
import { Box } from '@mui/material';
import CatchError from '@/components/system/CacthError';
import { getGameList } from '@/apis/hub88';

/* eslint-disable-next-line */
export interface CasinoProps {}

const StyledCasino = styled(Box)``;
// export const runtime = 'experimental-edge';

export function Casino(props: CasinoProps) {
  return <StyledCasino>{<CasinoPage />}</StyledCasino>;
}

export async function getServerSideProps({ locale, query }) {
  return {
    props: {
      ...(await getI18nProps({ locale })),
      gameList: (await getGameList(!!query['prod-list'])) || [],
    },
  };
}

export default CatchError(Casino);
