
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Image, { StaticImageData } from 'next/image';
import Link from 'next/link';

const ItemWrapper = styled(Link)(({ theme }) => ({
  flexShrink: 0,
  borderRadius: theme.spacing(1),
  border: theme.palette.border.normal,
  padding: theme.spacing(1),
  minWidth: '200px',
  cursor: 'pointer',
  display: 'grid',
  placeContent: 'center',
}));


export const GameProviderItem = ({
  provider,
}: {
  provider: { name: string; logo?: StaticImageData };
}) => {
  return (
    <ItemWrapper key={provider.name} href={`/casino/provider/${provider.name}`}>
      {!provider.logo ? (
        <Typography variant="h6">{provider.name}</Typography>
      ) : (
        <Image src={provider.logo} alt={provider.name} height={40} />
      )}
    </ItemWrapper>
  );
};
