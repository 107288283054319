// @ts-nocheck
import useTheme from '@/theme/useTheme';
import { Box, MenuItem, Typography } from '@mui/material';
import { styled, SxProps, Theme } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'next-i18next';
import { CustomSelect } from './styled';

const Wrapper = styled(Box)(({ theme }) => ({
  // paddingTop: theme.spacing(2),
  // paddingBottom: theme.spacing(2),
  textAlign: 'left',
  // background: theme.palette.grey[900],
  '& a': {
    textDecoration: 'none',
  },
  ['.MuiOutlinedInput-root']: {
    fontSize: theme.typography.pxToRem(18),
    width: '100%',
  },
  whiteSpace: 'nowrap',
  ['.MuiSelect-select']: {
    border: '1px solid rgba(255, 255, 255, 0.13)',
    ['&:hover, &[aria-expanded=true]']: {
      borderColor: theme.palette.primary.main,
    },
  },
}));

export type WrappedSelectorProps<T = string | number> = {
  /**
   * The title of the selector
   */
  title?: React.ReactNode;
  /**
   * The list of items to be rendered
   */
  list: T[];
  /**
   * optional render function
   * @param item
   * @returns React.ReactNode
   */
  render?: (item: T) => React.ReactNode;
  /**
   * The value of the selector
   */
  value: T;
  /**
   * the onChange handler
   * @param event
   * @returns
   */
  onChange: (
    event: React.ChangeEvent<{
      value: T;
    }>,
  ) => void;
  sx?: {
    wrapperSX?: SxProps<Theme>;
  };
  isDualOption?: boolean;
} & (typeof CustomSelect)['defaultProps'];

const WrappedSelector: <T>(props: WrappedSelectorProps<T>) => JSX.Element = ({
  title,
  list,
  isDualOption,
  render = (item) => item,
  sx: { wrapperSX = {} } = {},
  ...rest
}) => {
  const { theme } = useTheme();

  const [search, setSearch] = React.useState('');

  const [t] = useTranslation();

  const searchRef = React.useRef<HTMLInputElement>(null);

  const selectRef = React.useRef<HTMLDivElement>(null);

  return (
    <Wrapper sx={wrapperSX}>
      {title && (
        <Typography
          sx={{
            color: theme.palette.primary.contrastText,
            display: 'block',
            marginBottom: theme.spacing(0.5),
            cursor: 'pointer',
          }}
          component={'div'}
        >
          {title}
        </Typography>
      )}
      <CustomSelect
        {...rest}
        ref={selectRef}
        onOpen={(...args) => {
          if (rest.onOpen) rest.onOpen(...args);
          setTimeout(() => {
            searchRef.current?.focus();
          }, 0);
        }}
        onClose={(...args) => {
          if (rest.onClose) rest.onClose(...args);
          setTimeout(() => {
            setSearch('');
          }, 200);
        }}
        MenuProps={
          isDualOption
            ? {
                PaperProps: {
                  style: {
                    width: selectRef.current?.offsetWidth,
                  },
                },
                MenuListProps: {
                  style: {
                    display: 'flex',
                    flexWrap: 'wrap',
                  },
                },
              }
            : {}
        }
      >
        {list
          .map((item, index) => {
            return (
              <MenuItem
                sx={{
                  width: isDualOption ? '49%' : '100%',
                }}
                value={item as any}
                key={index}
              >
                <>{render(item)}</>
              </MenuItem>
            );
          })
          .filter(Boolean)}
      </CustomSelect>
    </Wrapper>
  );
};

export default WrappedSelector;
