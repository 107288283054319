import useTheme from '@/theme/useTheme';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Stack, StackProps, SxProps, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useIsomorphicLayoutEffect } from 'ahooks';
import React, { useEffect, useLayoutEffect } from 'react';

const scrollMap = {};

export const ArrowWrapper = styled((props: StackProps) => (
  <Stack {...props} direction={'row'} gap={2} />
))(({ theme }) => ({
  justifyContent: 'flex-end',

  alignSelf: 'center',
  ['> *']: {
    borderRadius: 12,
    padding: '4px',
  },
  ['svg']: {
    transition: 'opacity 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
}));

const HorizontalScrollArrow: React.FC<{
  sxs?: {
    wrapperSX?: SxProps<Theme>;
  };
  anchorRef: React.RefObject<Element>;
  recalculateDeps?: any[];
}> = ({ sxs, anchorRef, recalculateDeps = [] }) => {
  const { theme } = useTheme();
  const [scrollState, setScrollState] = React.useState<
    'start' | 'middle' | 'end' | 'none'
  >('none');

  useEffect(() => {
    if (anchorRef.current) {
      const textContent = anchorRef.current.textContent;
      anchorRef.current.addEventListener('scroll', (e) => {
        const scrollLeft = (e.target as HTMLDivElement).scrollLeft;
        scrollMap[textContent] = scrollLeft;
        const target = e.target as HTMLDivElement;
        if (scrollLeft === 0) {
          setScrollState('start');
        } else if (scrollLeft + target.clientWidth >= target.scrollWidth - 1) {
          setScrollState('end');
        } else {
          setScrollState('middle');
        }
      });
    }
  }, [anchorRef]);

  useIsomorphicLayoutEffect(() => {
    // console.log('~log~textContent', anchorRef, recalculateDeps);
    if (anchorRef.current) {
      if (anchorRef.current.clientWidth >= anchorRef.current.scrollWidth) {
        setScrollState('none');
      } else {
        setScrollState('start');
      }
      const textContent = anchorRef.current.textContent;
      if (scrollMap[textContent]) {
        if (anchorRef.current) {
          anchorRef.current.scrollLeft = scrollMap[textContent];
        }
      }
    }
  }, [anchorRef.current, ...recalculateDeps]);

  return (
    <ArrowWrapper
      sx={{
        ...(sxs?.wrapperSX || {}),
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      }}
    >
      <Stack direction={'row'}>
        <KeyboardArrowLeft
          sx={{
            cursor:
              scrollState === 'none'
                ? undefined
                : scrollState === 'start'
                  ? undefined
                  : 'pointer',
            opacity:
              scrollState === 'start' || scrollState === 'none' ? 0.2 : 1,
          }}
          onClick={() => {
            if (anchorRef.current) {
              anchorRef.current.scrollBy({
                left: -anchorRef.current.clientWidth * 0.8,
                behavior: 'smooth',
              });
            }
          }}
        ></KeyboardArrowLeft>
        <KeyboardArrowRight
          sx={{
            cursor: scrollState === 'end' ? undefined : 'pointer',
            opacity: scrollState === 'end' || scrollState === 'none' ? 0.2 : 1,
          }}
          onClick={() => {
            if (anchorRef.current) {
              anchorRef.current.scrollBy({
                left: anchorRef.current.clientWidth * 0.8,
                behavior: 'smooth',
              });
            }
          }}
        ></KeyboardArrowRight>
      </Stack>
    </ArrowWrapper>
  );
};

export default HorizontalScrollArrow;
