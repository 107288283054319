import BluePrintGamingLogo from './assets/Blueprint Gaming.png';
import ELKStudiosLogo from './assets/ELK Studios.png';
import KalambaGamesLogo from './assets/KalambaGames.png';
import OneTouchLogo from './assets/OneTouch.png';
import PragmaticPlayLogo from './assets/Pragmatic Play.png';
import SpinomenalLogo from './assets/Spinomenal.png';
import SpribeLogo from './assets/Spribe.png';
import WinFastLogo from './assets/WinFast.png';
// import WinFastLogo from './assets/WinFast.png';
// import GamomatLogo from './assets/Gamomat.png';

import AvatarUXLogo from './assets/AvatarUX.png';
import BetsoftLogo from './assets/Betsoft.png';
import GamzixLogo from './assets/Gamzix.png';
import OnlyplayLogo from './assets/Onlyplay.png';
import RetroGamingLogo from './assets/Retro Gaming.png';

// import OddinLogo from './assets/Oddin.png';
// import GametechLogo from './assets/Gametech.png';
// import BoomingGamesLogo from './assets/BoomingGames.png';
import { gameListSelector } from '@/state/globalSettings';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
// import { providerList } from "./game-providers";

export const providerList = [
  { name: 'Pragmatic Play', logo: PragmaticPlayLogo },
  { name: 'OneTouch', logo: OneTouchLogo },
  { name: 'Spribe', logo: SpribeLogo },
  { name: 'Retro Gaming', logo: RetroGamingLogo },
  { name: 'ELK Studios', logo: ELKStudiosLogo },
  //   { name: 'BetsyGames', logo: BetsyGamesLogo },
  { name: 'Spinomenal', logo: SpinomenalLogo },
  { name: 'Kalamba Games', logo: KalambaGamesLogo },
  { name: 'WinFast', logo: WinFastLogo },
  { name: 'Gamomat' },
  {
    name: 'Blueprint Gaming',
    logo: BluePrintGamingLogo,
  },

  { name: 'Gamzix', logo: GamzixLogo },
  { name: 'Betsoft', logo: BetsoftLogo },
  { name: 'AvatarUX', logo: AvatarUXLogo },
  { name: 'Onlyplay', logo: OnlyplayLogo },
  //   { name: 'BGaming', logo: BGamingLogo },
  //   { name: 'Caleta Gaming', logo: CaletaGamingLogo },

  //   { name: 'Oddin' },
  //   { name: '3Oaks', logo: ThreeOaksLogo },
  //   { name: 'Gametech' },
  //   { name: 'Booming Games' },
  //   { name: 'Endorphina', logo: EndorphinaLogo },
  //   { name: 'GameArt', logo: GameArtLogo },
];

export const useValidProviders = (providers: typeof providerList) => {
  const { list } = useAtomValue(gameListSelector);
  const validProviders = useMemo(() => {
    const productList = Array.from(
      new Set(list.map((game) => game.product)),
    ).map((product) => product.toLowerCase().replace(/\s+/g, ''));
    const validProviders = providerList.filter((provider) => {
      return productList.includes(provider.name.toLowerCase().replace(/\s+/g, ''));
    });
    return validProviders;
  }, [list]);
  return validProviders;
};
