import WrappedInput from '@/components/ui/common/WrappedInput';
import { LOCAL_KEY_GAME_SEARCH_HISTORY } from '@/constants/localStorageKeys';
import queryKeysFactory from '@/reactQueryKeys/keysFactory';
import { gameListSelector } from '@/state/globalSettings';
import useTheme from '@/theme/useTheme';
import { getLocalStorage, setLocalStorage } from '@/utils/localStoreHelper';
import requestIdleCallback from '@/utils/requestIdleCallback';
import { Close, CloseOutlined, History } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  ClickAwayListener,
  InputAdornment,
  Skeleton,
  Stack,
  Tooltip,
  TooltipProps,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import { useDebounce } from 'ahooks';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useAtomValue } from 'jotai';
import GameCard from '../game/GameCard';
import {
  HISTORY_SEARCH_ITEM_SX,
  SEARCH_RESULT_ITEM_SX,
  SEARCH_WRAPPER_SX,
} from './styled';

const getGameSearchHistoryFromLocal = (): string[] => {
  return getLocalStorage(LOCAL_KEY_GAME_SEARCH_HISTORY) || [];
};

const SearchResult: React.FC<{
  searchValue: string;
  inputRef: React.RefObject<HTMLInputElement>;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}> = ({ searchValue, inputRef, setValue }) => {
  const [t] = useTranslation();

  const { loading, list: gameList } = useAtomValue(gameListSelector);

  const searching = useDebounce(searchValue, {
    wait: 300,
  });

  const [historyList, setHistoryList] = useState<string[]>([]);

  useEffect(() => {
    setHistoryList(getGameSearchHistoryFromLocal());
    return () => {};
  }, []);

  const addHistoryEntry = (entry: string) => {
    const index = historyList.indexOf(entry);
    if (index !== -1) {
      historyList.splice(index, 1);
    }
    const newList = [entry, ...historyList].slice(0, 5);
    setLocalStorage(LOCAL_KEY_GAME_SEARCH_HISTORY, newList);
    setHistoryList(newList);
  };

  const removeHistoryEntry = (entry: string) => {
    const newList = historyList.filter((item) => item !== entry);
    setLocalStorage(LOCAL_KEY_GAME_SEARCH_HISTORY, newList);
    setHistoryList(newList);
  };

  const { data: searchingResult, isLoading } = useQuery({
    queryKey: queryKeysFactory.searchGame(searching),
    queryFn: () => {
      return gameList.filter((item) =>
        item.name.toLowerCase().includes(searching.toLowerCase()),
      );
      // return new Promise<IHub88GameItem[]>((resolve) => {
      //   setTimeout(() => {
      //     return resolve(
      //       gameList.filter((item) =>
      //         item.name.toLowerCase().includes(searching.toLowerCase()),
      //       ),
      //     );
      //   }, 0);
      // });
    },

    enabled: searching.length >= 1,
    onSettled(data) {
      if (data.length) {
        addHistoryEntry(searching);
      }
    },
  });

  let content: React.ReactNode = null;

  if (!isLoading && !searchingResult?.length) {
    content = (
      <Typography textAlign={'center'} variant="body1">
        {t('No result')}
      </Typography>
    );
  } else {
    if (isLoading) {
      content = (
        <Box sx={SEARCH_WRAPPER_SX}>
          {Array.from({ length: 5 }).map((_, index) => (
            <Box sx={SEARCH_RESULT_ITEM_SX} key={index}>
              <Skeleton
                variant="rounded"
                animation="wave"
                width={'100%'}
                height={'100%'}
              />
            </Box>
          ))}
        </Box>
      );
    } else {
      content = (
        <Box sx={SEARCH_WRAPPER_SX}>
          {searchingResult?.map((item) => (
            <GameCard
              game={item}
              key={item.gameId}
              sx={SEARCH_RESULT_ITEM_SX}
            ></GameCard>
          ))}
        </Box>
      );
    }
  }

  if (!historyList.length && !searchValue) {
    return null;
  }

  return (
    <Stack gap={2} py={2}>
      {/*<Typography*/}
      {/*  textAlign={'center'}*/}
      {/*  variant="body1"*/}
      {/*  display={searchValue.length < 3 ? 'initial' : 'none'}*/}
      {/*>*/}
      {/*  {t('Search requires at least 3 characters')}*/}
      {/*</Typography>*/}
      <Box display={searchValue.length >= 1 ? 'initial' : 'none'} padding={1}>
        {content}
      </Box>
      <Box
        display={
          !historyList.length || searchingResult?.length ? 'none' : 'initial'
        }
        sx={{
          mb: -2,
          mt: -2,
        }}
      >
        {historyList.map((item) => (
          <Stack
            key={item}
            justifyContent="space-between"
            alignItems={'center'}
            direction={'row'}
            onClick={() => {
              setValue(item);
              requestIdleCallback(() => {
                inputRef.current?.focus();
              });
            }}
            sx={HISTORY_SEARCH_ITEM_SX}
            gap={1}
          >
            <History fontSize={'small'} />
            <Typography variant="body1" flexGrow={1}>
              {item}
            </Typography>
            <CloseOutlined
              onClick={(e) => {
                removeHistoryEntry(item);
                e.stopPropagation();
              }}
              sx={{
                color: 'rgba(255, 255, 255, 0.56)',
                '&:hover': {
                  color: 'rgba(255, 255, 255, 0.8)',
                },
              }}
            />
          </Stack>
        ))}
      </Box>
      {/* <Box>
        <Typography variant="h6" textAlign={'center'}>
          {t('Recommended')}
        </Typography>
      </Box> */}
    </Stack>
  );
};

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({});

export default function GameSearch({
  mode = 'popup',
  onChange,
  autoFocus = false,
}: {
  mode?: 'page' | 'popup' | 'searchOnly';
  onChange?: (value: string) => void;
  autoFocus?: boolean;
}) {
  const [t] = useTranslation();

  const { theme } = useTheme();

  const [value, setValue] = React.useState('');

  const [open, setOpen] = React.useState(false);

  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleTooltipClose = () => {
    setOpen(false);
    // setTimeout(() => {
    //   setValue('');
    // }, 200);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const searchInput = (
    <WrappedInput
      autoFocus={autoFocus}
      fullWidth
      style={{
        padding: '8px 16px',
        zIndex: 1,
        fontWeight: theme.typography.fontWeightMedium,
        height: 48,
      }}
      onClick={handleTooltipOpen}
      value={value}
      onChange={(event) => {
        setValue(event.target.value);
        onChange && onChange(event.target.value);
      }}
      placeholder={t('Search Games')}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
      endAdornment={
        value ? (
          <InputAdornment
            position="end"
            sx={{
              cursor: 'pointer',
            }}
          >
            <Close
              onClick={() => {
                setValue('');
                requestIdleCallback(() => {
                  inputRef.current?.focus();
                });
                onChange && onChange('');
              }}
              sx={{
                '&:hover': {
                  color: 'rgba(255, 255, 255, 0.8)',
                },
              }}
            />
          </InputAdornment>
        ) : null
      }
      inputRef={inputRef}
    />
  );

  const searchResult = (
    <SearchResult searchValue={value} inputRef={inputRef} setValue={setValue} />
  );

  if (mode === 'page') {
    return (
      <Stack flexGrow={1}>
        {searchInput}
        {searchResult}
      </Stack>
    );
  }
  if (mode === 'searchOnly') {
    return searchInput;
  } else {
    return (
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Box
          sx={{
            position: 'relative',
            flexGrow: 1,
            ['.MuiTooltip-popper']: {
              width: '100%',
            },
            ['.MuiTooltip-tooltip']: {
              marginTop: '4px !important',
              boxShadow: theme.palette.boxShadow.elevation8,
              background: theme.palette.grey[800],
              maxWidth: 'none',
              padding: 0,
              overflow: 'hidden',
              maxHeight: '40vh',
              overflowY: 'auto',
              [theme.breakpoints.down('sm')]: {
                maxHeight: '60vh',
              },
            },
          }}
        >
          <NoMaxWidthTooltip
            PopperProps={{
              disablePortal: true,
            }}
            open={open}
            disableHoverListener
            disableTouchListener
            title={searchResult}
            sx={{
              boxShadow: theme.palette.boxShadow.elevation8,
            }}
            //   TransitionComponent={Fade}
          >
            {searchInput}
          </NoMaxWidthTooltip>
        </Box>
      </ClickAwayListener>
    );
  }
}
