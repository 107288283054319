import { TGameListItem } from '@/apis/hub88';
import { GameCategoryEnum } from '@/types/api';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

export const tableGamesFilter = ({ category }: TGameListItem) => {
  return [
    GameCategoryEnum['Table Games'],
    GameCategoryEnum['Other Table Games'],
    GameCategoryEnum['Bet On Poker'],
    GameCategoryEnum['Live Baccarat'],
    GameCategoryEnum['Live Blackjack'],
    GameCategoryEnum['Live Roulette'],
  ].includes(category);
};

export const originalGamesFilter = ({ provider }: TGameListItem) => {
  return provider === 'SELF';
};

export const blackjackGamesFilter = ({ category, gameId }: TGameListItem) => {
  return [
    GameCategoryEnum['Live Blackjack'],
    GameCategoryEnum['Blackjack'],
  ].includes(category);
  // return category.toLowerCase().includes('blackjack');
  // return (
  //   category === 'Live Blackjack' ||
  //   gameId === 'ont_supremeblackjackmhpp' ||
  //   gameId === 'evo_stspeedblackjacka' ||
  //   gameId === 'ont_blackjackclassic' ||
  //   gameId === 'bbl_bombayliveblackjacklobby'
  // );
};

export const baccaratGamesFilter = ({ category, gameId }: TGameListItem) => {
  return [
    GameCategoryEnum['Live Baccarat'],
    GameCategoryEnum['Baccarat'],
  ].includes(category);
};

export const rouletteGamesFilter = ({ category, gameId }: TGameListItem) => {
  return category === 'Roulette' || gameId === 'ont_roulette';
};

export const liveCasinoGamesFilter = ({ category }: TGameListItem) => {
  return [
    GameCategoryEnum['Live Baccarat'],
    GameCategoryEnum['Live Blackjack'],
    GameCategoryEnum['Live Roulette'],
    GameCategoryEnum['Live Dice'],
    GameCategoryEnum['Live Game Shows'],
    GameCategoryEnum['Live Dragon Tiger'],
    GameCategoryEnum['Live Poker'],
    GameCategoryEnum['Other Live Games'],
    GameCategoryEnum['Bombay Live'],
  ].includes(category);
};

export const slotsGamesFilter = ({ category }: TGameListItem) => {
  return category === 'Video Slots';
};

export enum SortByEnum {
  DEFAULT,
  // NEW_RELEASES,
  ALPHABETICAL_A_Z,
  ALPHABETICAL_Z_A,
}

export const useCasinoTitle = (tab) => {
  const [t] = useTranslation();
  return useMemo(() => {
    switch (tab) {
      case 'lobby':
        return t('Play Real Money Online Casino Games');
      case 'slots':
        return t('Play the Best RTP Slot Games');
      case 'table-games':
        return t('Bet on the Online Casino Tables with Rakeback');
      case 'live-casino':
        return t('Play the Best Live Dealer Games');
      case 'blackjack':
        return t('Play Online Blackjack Games with Rakeback');
      case 'baccarat':
        return t('Play Online Baccarat with Money Back on Every Bet');
      case 'roulette':
        return t('Play Online Roulette with Real Money');
      case 'originals':
        return t('Play Exclusive Casino Games with Rakeback Rewards');
      default:
        return t('Play Real Money Online Casino Games');
    }
  }, [tab, t]);
};

export const useCasinoDescription = (tab) => {
  const [t] = useTranslation();

  return useMemo(() => {
    const defaultValue = t(
      'Join MoonClub Casino with no KYC and get money back on every bet with Rakeback. Earn referral commission and play on Telegram Casino. Get started with a bonus!',
    );
    switch (tab) {
      case 'lobby':
        return defaultValue;
      case 'slots':
        return t(
          'Play the best online slot games with no KYC or verification. MoonClub has the best RTP slot games thanks to our generous 6% Rakeback on every bet!',
        );
      case 'table-games':
        return t(
          'Enjoy real money table games and get money back on every bet with 6% Rakeback. Play blackjack, roulette, baccarat, poker, and more. Sign up with no KYC.',
        );
      case 'live-casino':
        return t(
          'Experience the best live dealer tables with no KYC. MoonClub features the most immersive live games, including blackjack, roulette, baccarat and more.',
        );
      case 'blackjack':
        return t(
          'Hit up the blackjack tables and get money back on every bet at MoonClub – The Highest Rewarding Crypto Casino. Sign up with no verification.',
        );
      case 'baccarat':
        return t(
          'Enjoy the baccarat tables at MoonClub and take advantage of 6% Rakeback. MoonClub is the highest rewarding crypto casino. Sign up with no KYC.',
        );
      case 'roulette':
        return t(
          'Experience the thrilling roulette tables at MoonClub and take advantage of 6% Rakeback. MoonClub is the highest rewarding crypto casino.',
        );
      case 'originals':
        return t(
          'MoonClub is the only casino where you can play the Moon Rush game and take advantage of 6% Rakeback. Making MoonClub the best RTP online casino.',
        );
      default:
        return defaultValue;
    }
  }, [tab, t]);
};
