// import WinFastLogo from './assets/WinFast.png';
// import GamomatLogo from './assets/Gamomat.png';

// import OddinLogo from './assets/Oddin.png';
// import GametechLogo from './assets/Gametech.png';
// import BoomingGamesLogo from './assets/BoomingGames.png';
import HorizontalScrollArrow from '@/components/ui/HorizontalScrollArrow';
import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import React, { useMemo } from 'react';
import { GameProviderItem } from './game-providers';
import Link from 'next/link';
import { useAtomValue } from 'jotai';
import { gameListSelector } from '@/state/globalSettings';
import { providerList, useValidProviders } from './helper';

const noLogoProviders = [
  'Kalamba Games',
  'Oddin',
  'WinFast',
  'Gamomat',
  'Gametech',
  'Booming Games',
];

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
  overflow: 'auto',
  width: '100%',
  '::-webkit-scrollbar': {
    display: 'none',
  },
}));

const GameProviders = () => {
  const [t] = useTranslation();
  const ref = React.useRef<HTMLDivElement>(null);

  const validProviders = useValidProviders(providerList);

  return (
    <Stack gap={1} mt={[2, 2, 3, 3]}>
      <Stack
        direction={'row'}
        gap={1}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography>{t('Game Providers')}</Typography>
        <HorizontalScrollArrow anchorRef={ref} />
      </Stack>
      <Wrapper direction={'row'} gap={1} ref={ref}>
        {validProviders.map((provider) => {
          return <GameProviderItem key={provider.name} provider={provider} />;
        })}
      </Wrapper>
    </Stack>
  );
};

export default GameProviders;
