import { Button, Link, Typography } from '@mui/material';
import { Box, Stack, width } from '@mui/system';
import { Trans, useTranslation } from 'next-i18next';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import contentMap from './content';

const StyledSectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  marginBottom: theme.spacing(2),
  fontWeight: 400,
  lineHeight: 1,
}));
const StyledSectionText = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  marginBottom: theme.spacing(3),
  fontWeight: 400,
  lineHeight: 2,
}));

const AdditionText = (props: { currentTab: string }) => {
  const [collapse, setCollapse] = useState(true);
  const { t } = useTranslation();

  const content = contentMap[props.currentTab] || contentMap.casino;

  return (
    <Stack
      alignItems={'center'}
      sx={{
        p: 4,
        mt: 3,
        position: 'relative',
      }}
    >
      <Typography
        variant="h4"
        fontSize={'24px'}
        fontWeight={500}
        lineHeight={1}
        marginBottom={2}
        color="text.primary"
      >
        {t(content.title)}
      </Typography>
      {content.abstractH2 ? (
        <StyledSectionTitle sx={{ textAlign: 'left', width: '100%' }}>
          {t(content.abstractH2)}
        </StyledSectionTitle>
      ) : (
        <></>
      )}
      {content.abstractText ? (
        <Typography
          variant="body2"
          fontSize={'12px'}
          lineHeight={2}
          fontWeight={400}
          color="text.primary"
        >
          {t(content.abstractText)}
        </Typography>
      ) : (
        <></>
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: { xs: 0, md: 8 },
          my: 4,
          height: {
            md: collapse ? '72px' : 'auto',
            xs: collapse ? 0 : 'auto',
          },
          overflow: 'hidden',
          transition: 'height 1s ease',
        }}
      >
        <Stack flex={1}>
          {content.left.map((item: any, idx) => {
            return item.type === 'title' ? (
              <StyledSectionTitle key={idx}>
                <Trans i18nKey={t(item.text)} components={item.components} />
              </StyledSectionTitle>
            ) : (
              <StyledSectionText key={idx}>
                <Trans i18nKey={t(item.text)} components={item.components} />
              </StyledSectionText>
            );
          })}
        </Stack>
        <Stack flex={1}>
          {content.right.map((item: any, idx) => {
            return item.type === 'title' ? (
              <StyledSectionTitle key={idx}>
                <Trans i18nKey={t(item.text)} components={item.components} />
              </StyledSectionTitle>
            ) : (
              <StyledSectionText key={idx}>
                <Trans i18nKey={t(item.text)} components={item.components} />
              </StyledSectionText>
            );
          })}
        </Stack>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          height: '120px',
          background: collapse
            ? 'linear-gradient(0deg, #181a1f 50%, rgba(22, 22, 27, 0.00) 100%)'
            : '',
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'center',
        }}
      >
        <Button
          color={'primary'}
          variant={'outlined'}
          onClick={() => {
            setCollapse(!collapse);
          }}
          sx={{ mb: 2 }}
        >
          {collapse ? t('Show More') : t('Show Less')}
        </Button>
      </Box>
    </Stack>
  );
};
export default AdditionText;
